import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableProductCategory() {
  // Use toast
  const toast = useToast()

  const refProductCategoryTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('TitleMaster.ProductCategory') }),
    id: `${t('ID')}`,
    type_code: `${t('FormProductCategory.ProductType')}`,
    name: `${t('FormProductCategory.Name')}`,
    desc: `${t('FormProductCategory.Description')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    {
      key: 'id', sortable: true, label: msgList.id, class: 'text-center',
    },
    { key: 'type_code', sortable: true, label: msgList.type_code },
    { key: 'name', sortable: true, label: msgList.name },
    { key: 'desc', sortable: true, label: msgList.desc },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalProductCategorys = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('type_code')
  const isSortDirDesc = ref(false)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refProductCategoryTable.value ? refProductCategoryTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProductCategorys.value,
    }
  })

  const refetchData = () => {
    if (refProductCategoryTable.value !== null) {
      refProductCategoryTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListProductCategory = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
    }
    store
      .dispatch('store-product-category/getListProductCategory', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalProductCategorys.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-product-category`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  return {
    getListProductCategory,
    tableColumns,
    perPage,
    currentPage,
    totalProductCategorys,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refProductCategoryTable,

    refetchData,
  }
}
